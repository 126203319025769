import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

import "./carousel.css";

export const CarouselItem = ({ children, width }) => {
  return (
    <figure
      className="carousel-item"
      style={{
        width: width,
      }}
    >
      {children}
    </figure>
  );
};

const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div {...handlers} className="carousel" style={{ position: "relative" }}>
      <div
        id="carousel-item-wrapper"
        className="carousel-item-wrapper"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        onClick={() => updateIndex(activeIndex + 1)}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div
        style={{
          position: "absolute",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          bottom: "calc(50% - 0.75rem)",
        }}
      >
        <button
          class="pkt-btn pkt-btn--large pkt-btn--primary pkt-btn--icon-only pkt-btn--yellow"
          style={{
            borderRadius: "100%",
          }}
          type="button"
          aria-label="Vis forrige bilde"
          onClick={() => updateIndex(activeIndex - 1)}
        >
          <span class="pkt-icon pkt-btn__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="chevron-left"
              fill="none"
              data-category="arrows"
              viewBox="0 0 32 32"
            >
              <path
                fill="var(--fg-color, #2A2859)"
                fill-rule="evenodd"
                d="m6.8571 15.8571 10 10 4.2858-4.2857-5.7143-5.7143 5.7143-5.7142-4.2858-4.2858-10 10ZM24 10.1429l-5.7143 5.7142L24 21.5714l-7.1429 7.1429L4 15.8571 16.8571 3 24 10.1429Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
        </button>
        <button
          class="pkt-btn pkt-btn--large pkt-btn--primary pkt-btn--icon-only pkt-btn--yellow"
          style={{
            borderRadius: "100%",
          }}
          type="button"
          aria-label="Vis neste bilde"
          onClick={() => updateIndex(activeIndex + 1)}
        >
          <span class="pkt-icon pkt-btn__icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="chevron-right"
              fill="none"
              data-category="arrows"
              viewBox="0 0 32 32"
            >
              <path
                fill="var(--fg-color, #2A2859)"
                fill-rule="evenodd"
                d="m25.1429 15.8571-10-10-4.2858 4.2858 5.7143 5.7142-5.7143 5.7143 4.2858 4.2857 10-10ZM8 10.1429 15.1429 3 28 15.8571 15.1429 28.7143 8 21.5714l5.7143-5.7143L8 10.1429Z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </span>
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: "calc(50% - 0.75rem)",
        }}
      ></div>
      <div style={{ display: "flex", justifyContent: "flex-end" }} aria-hidden>
        <span>{`Viser bilde ${activeIndex + 1}/${React.Children.count(
          children
        )}`}</span>
      </div>
    </div>
  );
};

export default Carousel;
