import packageJson from "../../../package.json";

const VersionPage = () => {
    return (
        <section style={{padding: 50}}>
            <h1>Version</h1>
            <h2>Mitt Gebyr</h2>
            <div><b>Version</b></div>
            <div>{packageJson.version}</div>
        </section>
    );
}

export default VersionPage;