import "./App.css";
import { ReactComponent as OsloLogo } from "./images/oslo-logo.svg";

function Layout(props) {
  return (
    <>
      <header id="pkt-header" aria-label="Topp" className="pkt-header">
        <div className="pkt-header__logo">
          <a
            aria-label="Tilbake til forside"
            href={window.location.origin}
            className="pkt-header__logo-link"
          >
            <span className="pkt-icon pkt-header__logo-svg">
              <OsloLogo />
            </span>
          </a>
          <span className="pkt-header__logo-service">Mitt Gebyr</span>
        </div>
        <nav className="pkt-header__actions">
          <ul className="pkt-header__actions-row">
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="pkt-link pkt-link--external"
                href="https://uustatus.no/nb/erklaringer/publisert/6a7091e5-5b30-4fbd-bc23-b581dfc1ce29"
              >
                Tilgjengelighet
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main className="app">{props.children}</main>
    </>
  );
}

export default Layout;
