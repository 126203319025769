import axios from "axios";
import url from "./url";

export const getIleggelseKlageStatistikk = async (ileggelseId) => {
  let response = await axios.get(
    url.ileggelseService.getIleggelseKlageStatistikk(ileggelseId)
  );
  if (response?.data && response.status) {
    return response.data;
  }
};

export const putIleggelseKlageStatistikk = async (ileggelseId, params) => {
  let response = await axios.put(
    url.ileggelseService.putIleggelseKlageStatistikk(ileggelseId),
    params
  );
  if (response?.data && response.status) {
    return response.data;
  }
};