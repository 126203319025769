import BildebevisPage from "./components/Bildebevis/BildebevisPage";

import "./App.css";

function App() {
  return (
      <>
        <h1 className="sr-only">Mitt Gebyr</h1>
        <BildebevisPage />
      </>
  );
}

export default App;
