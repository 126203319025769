import { ReactComponent as NotFoundImage } from "../../images/404-illustrasjon.svg";

const PageNotFound = () => {
  return (
    <section
      className="pkt-grid pkt-grid--colgap-size-16"
      style={{ paddingBottom: "10rem" }}
    >
      <div className="pkt-cell pkt-cell--span12 pkt-cell--span6-laptop-up">
        <h1 className="pkt-txt-54--phablet-up mb-size-24 mb-size-32--phablet-up">
          Beklager, vi finner ikke siden du leter etter.
        </h1>
        <p className="mb-size-24 mb-size-32--phablet-up">
          For å få ett treff på denne siden må du bruke lenken fra klageskjemaet
          for "Klage på bot". Hvis lenken ikke fungerer er det fordi ileggelsen
          er eldre enn 45 dager.
        </p>
        <a
          href="https://www.oslo.kommune.no/gate-transport-og-parkering/parkering/klag-pa-bot"
          aria-label="til 'klag på bot'"
          className="pkt-btn pkt-btn--secondary"
        >
          <span className="pkt-btn__text">Gå til «Klag på bot»</span>
        </a>
      </div>
      <div className="pkt-cell pkt-cell--center-vertical pkt-cell--span12 pkt-cell--span6-laptop-up pkt-hide pkt-show-phablet-up">
        <NotFoundImage />
      </div>
    </section>
  );
};

export default PageNotFound;
