import { getEnvironmentVariable } from "../../utils/environmentUtils";

const ileggelseServiceApiUrl = getEnvironmentVariable("REACT_APP_ILEGGELSER_SERVICE_API_URL");

const url = {
  ileggelseService: {
    getBildebevisPageIleggelse: `${ileggelseServiceApiUrl}/bildebevis-page/ileggelse`, //"http://localhost:5206/api/bildebevis-page/ileggelse",
    getBildebevisPageBildeBase64: (ileggelseId, imageId) =>
      `${ileggelseServiceApiUrl}/bildebevis-page/ileggelser/${ileggelseId}/bilder/${imageId}`, //`http://localhost:5206/api/bildebevis-page/ileggelser/${ileggelseId}/bilder/${imageId}`,
    getIleggelseKlageStatistikk: (ileggelseId) => `${ileggelseServiceApiUrl}/ileggelse-klage-statistikk/${ileggelseId}`,
    putIleggelseKlageStatistikk: (ileggelseId) => `${ileggelseServiceApiUrl}/ileggelse-klage-statistikk/${ileggelseId}`,
  },
};

export default url;
