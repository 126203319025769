import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import "./main.scss";
import { loader as bildebevisPageLoader } from "./components/Bildebevis/BildebevisPage";

import VersionPage from "components/Version/VersionPage";

import reportWebVitals from "./reportWebVitals";
import Layout from "Layout";
import PageNotFound from "components/PageNotFound/PageNotFound";
import Loader from "components/common/Loader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageNotFound />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/version",
    element: <VersionPage />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/:kode",
    element: <App />,
    errorElement: <PageNotFound />,
    loader: bildebevisPageLoader,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Layout>
    <RouterProvider router={router} fallbackElement={<Loader />} />
  </Layout>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
