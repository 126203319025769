export const base64toBlob = async (base64) => {
  const base64Response = await fetch(`data:image/jpeg;base64,${base64}`);

  return await base64Response.blob();
};

export const base64ToBlobUrl = async (base64Data) => {
  const blob = await base64toBlob(base64Data);
  const url = window.URL.createObjectURL(blob);

  return url;
};
