import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";

import Gebyrinfo from "./Gebyrinfo";
import Carousel, { CarouselItem } from "../Image-Carousel/Carousel";

import {
  getBildebevisPageIleggelse,
  getBildebevisPageBildeBase64,
} from "../../api/IleggelseServiceEndpoints/bildebevisPageEndpoints";

import {
  getIleggelseKlageStatistikk,
  putIleggelseKlageStatistikk,
} from "../../api/IleggelseServiceEndpoints/ileggelseKlageStatistikkEndpoints";

export const loader = async ({ params }) => {
  const bildebevisPageIleggelse = await getBildebevisPageIleggelse(params.kode);
  if (bildebevisPageIleggelse && bildebevisPageIleggelse.errorMessage) {
    console.log("ErrorMessage:", bildebevisPageIleggelse.errorMessage);
    return;
  }

  const ileggelseId = bildebevisPageIleggelse.result?.id;
  const ileggelseKlageStatistikk = await getIleggelseKlageStatistikk(
    ileggelseId
  );
  if (ileggelseKlageStatistikk && ileggelseKlageStatistikk.result) {
    const bildebevisCounter =
      ileggelseKlageStatistikk.result.bildeBevisCounter >= 0
        ? ileggelseKlageStatistikk.result.bildeBevisCounter + 1
        : undefined;
    await putIleggelseKlageStatistikk(
      ileggelseKlageStatistikk.result.ileggelseId,
      { bildeBevisCounter: bildebevisCounter }
    );
  }

  return bildebevisPageIleggelse?.result;
};

const BildebevisPage = () => {
  const [imgElLoadedCount, setImgElLoadedCount] = useState(0);
  const [images, setImages] = useState([]);
  const [smallestImgElNaturalHeight, setSmallestImgElNaturalHeight] =
    useState(undefined);
  const [imgMaxHeight, setImgMaxHeight] = useState("");

  const bildebevisPageIleggelseViewModel = useLoaderData();

  useEffect(() => {
    if (bildebevisPageIleggelseViewModel) {
      setImagesFunc(
        bildebevisPageIleggelseViewModel?.id,
        bildebevisPageIleggelseViewModel?.bildebevisBildeIds
      );
    }
  }, [bildebevisPageIleggelseViewModel]);

  useEffect(() => {
    const resizeEventHandler = () => {
      const carouselItemWrapperEl = document.getElementById(
        "carousel-item-wrapper"
      );
      if (smallestImgElNaturalHeight && carouselItemWrapperEl) {
        setImgMaxHeight(
          carouselItemWrapperEl.clientWidth *
            (smallestImgElNaturalHeight.naturalHeight /
              smallestImgElNaturalHeight.naturalWidth)
        );
      }
    };

    window.addEventListener("resize", resizeEventHandler);

    return () => {
      window.removeEventListener("resize", resizeEventHandler);
    };
  }, [smallestImgElNaturalHeight]);

  useEffect(() => {
    if (
      imgElLoadedCount !== 0 &&
      images.length !== 0 &&
      imgElLoadedCount === images.length
    ) {
      let tempSmallestImageNaturalHeight = undefined;
      let tempSmallestImgElNaturalHeight = undefined;
      for (let index = 0; index < images.length; index++) {
        const image = images[index];
        const imgEl = document.getElementById(image.id);
        if (!imgEl) return;
        if (
          !tempSmallestImageNaturalHeight ||
          tempSmallestImageNaturalHeight > imgEl.naturalHeight
        ) {
          tempSmallestImageNaturalHeight = imgEl.naturalHeight;
          tempSmallestImgElNaturalHeight = imgEl;
        }
      }
      const carouselItemWrapperEl = document.getElementById(
        "carousel-item-wrapper"
      );

      if (tempSmallestImgElNaturalHeight && carouselItemWrapperEl) {
        setImgMaxHeight(
          carouselItemWrapperEl.clientWidth *
            (tempSmallestImgElNaturalHeight.naturalHeight /
              tempSmallestImgElNaturalHeight.naturalWidth)
        );
        setSmallestImgElNaturalHeight(tempSmallestImgElNaturalHeight);
      }
    }

    return;
  }, [imgElLoadedCount, images]);

  const setImagesFunc = async (ileggelseId, imageIds) => {
    let tempImages = [];

    for (let index = 0; index < imageIds.length; index++) {
      const imageId = imageIds[index];
      const bildeUrl = await getBildebevisPageBildeBase64(ileggelseId, imageId);
      if (!bildeUrl) continue;
      tempImages = [
        ...tempImages,
        {
          id: `carousel-item-img-${index}`,
          index: index,
          url: bildeUrl,
          alt: `Bilde nummer ${index + 1} av ${imageIds.length}`,
        },
      ];
    }

    setImages(tempImages);
  };

  const overtredelseforklaringSplitIntoParagraphs = (
    forklaring,
    overtredelse
  ) => {
    // overtredelse = overtredelse1, overtredelse2, or overtredelse3
    const forklaringParagraphs = forklaring.split("\n\n");
    return forklaringParagraphs.map((fp, index) => (
      <p key={`forklaringOvertredelse${overtredelse}Paragrapth${index}`}>
        {fp}
      </p>
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <section>
        <h2>Min overtredelse</h2>
        <p>
          Her finner du informasjon om boten du har fått, forklaring på hvorfor
          du har fått bot og bilder av overtredelsen.
        </p>
      </section>
      <section>
        <Gebyrinfo
          bildebevisPageIleggelseViewModel={bildebevisPageIleggelseViewModel}
        />
      </section>
      <section>
        <h2>Hvorfor har jeg fått bot?</h2>
        {bildebevisPageIleggelseViewModel?.overtredelse1Forklaring && (
          <>
            {overtredelseforklaringSplitIntoParagraphs(
              bildebevisPageIleggelseViewModel.overtredelse1Forklaring,
              1
            )}
          </>
        )}
        {bildebevisPageIleggelseViewModel?.overtredelse2Forklaring && (
          <>
            {overtredelseforklaringSplitIntoParagraphs(
              bildebevisPageIleggelseViewModel.overtredelse2Forklaring,
              2
            )}
          </>
        )}
        {bildebevisPageIleggelseViewModel?.overtredelse3Forklaring && (
          <>
            {overtredelseforklaringSplitIntoParagraphs(
              bildebevisPageIleggelseViewModel.overtredelse3Forklaring,
              3
            )}
          </>
        )}
      </section>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
        }}
      >
        <h2>Bilder av overtredelsen</h2>
        {images && images.length > 0 && (
          <div>
            <Carousel>
              {images.map((image) => {
                return (
                  <CarouselItem key={`carousel-item-${image.index}`}>
                    <img
                      id={image.id}
                      src={image.url}
                      alt={image.alt}
                      onLoad={() =>
                        setImgElLoadedCount(
                          (prevImgElLoadedCount) => prevImgElLoadedCount + 1
                        )
                      }
                      style={{ maxHeight: imgMaxHeight }}
                    />
                    <figcaption className="sr-only">{image.alt}</figcaption>
                  </CarouselItem>
                );
              })}
            </Carousel>
          </div>
        )}
      </section>
    </div>
  );
};

export default BildebevisPage;
