const TableBody = ({ bildebevisPageIleggelseViewModel }) => {
  function getISOLocalString(dato) {
    if (!dato) return "";
    let date = new Date(dato);

    let tzo = -date.getTimezoneOffset();
  
    if (tzo === 0) {
  
      return date.toISOString();
  
    } else {
  
      let dif = tzo >= 0 ? '+' : '-';
      let pad = function(num, digits=2) {
        return String(num).padStart(digits, "0");
      };
  
      return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
    }
  }

  const getDato = (datoString) => {
    if (!datoString) return "";
    const date = new Date(datoString);

    return date.toLocaleDateString("nb-NO");
  };

  const getISODato = (datoString) => {
    const output = getISOLocalString(datoString);
    return output === "" ? "" : output.split("T")[0];
  };

  const getTid = (tidString) => {
    if (!tidString) return "";
    const date = new Date(tidString);

    return date.toLocaleTimeString(
      "nb-NO"
    );
  };

  const getISOTid = (tidString) => {
    const output = getISOLocalString(tidString);
    return output === "" ? "" : output.split("T")[1].split(/(\+|-)/g)[0];
  }

  if (window.innerWidth >= 500) {
    return (
      <table id="gebyrinfo">
        <caption className="sr-only">Informasjon om boten</caption>
        <tbody>
          <tr>
            <th scope="row">Ileggelsesnummer</th>
            <td style={{ paddingLeft: 10 }}>
              {bildebevisPageIleggelseViewModel?.gebyrnummer}
            </td>
          </tr>
          <tr>
            <th scope="row">Kjennemerke</th>
            <td style={{ paddingLeft: 10 }}>
              {bildebevisPageIleggelseViewModel?.kjennemerke}
            </td>
          </tr>
          <tr>
            <th scope="row">Firma</th>
            <td style={{ paddingLeft: 10 }}>
              {bildebevisPageIleggelseViewModel?.firma}
            </td>
          </tr>
          <tr>
            <th scope="row">Dato</th>
            <td style={{ paddingLeft: 10 }}>
              <time dateTime={getISODato(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}>{getDato(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}</time>
            </td>
          </tr>
          <tr>
            <th scope="row">Tidspunkt</th>
            <td style={{ paddingLeft: 10 }}>
              <time dateTime={getISOTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}>{getTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}</time>
              {" - "}
              <time dateTime={getISOTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoTil)}>{getTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoTil)}</time>
            </td>
          </tr>
          <tr>
            <th scope="row">Gatenavn</th>
            <td style={{ paddingLeft: 10 }}>
              {bildebevisPageIleggelseViewModel?.gatenavn}
            </td>
          </tr>
          <tr>
            <th scope="row">Gatenummer</th>
            <td style={{ paddingLeft: 10 }}>
              {bildebevisPageIleggelseViewModel?.gatenummer}
            </td>
          </tr>
          <tr>
            <th scope="row">Type ileggelse</th>
            <td style={{ paddingLeft: 10 }}>
              {bildebevisPageIleggelseViewModel?.ileggelsetype}
            </td>
          </tr>
          <tr>
            <th scope="row">Forfallsdato</th>
            <td style={{ paddingLeft: 10 }}>
              <time dateTime={getISODato(bildebevisPageIleggelseViewModel?.forfallodato)}>{getDato(bildebevisPageIleggelseViewModel?.forfallodato)}</time>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  return (
    <ul className="gebyrinfo-small-screen">
      <li>
        <label htmlFor="gebyrnummer">Ileggelsesnummer</label>
        <br />
        <span id="gebyrnummer">{bildebevisPageIleggelseViewModel?.gebyrnummer}</span>
      </li>
      <li>
        <label htmlFor="kjennemerke">Kjennemerke</label>
        <br />
        <span id="kjennemerke">{bildebevisPageIleggelseViewModel?.kjennemerke}</span>
      </li>
      <li>
        <label htmlFor="firma">Firma</label>
        <br />
        <span id="firma">{bildebevisPageIleggelseViewModel?.firma}</span>
      </li>
      <li>
        <label htmlFor="dato">Dato</label>
        <br />
        <time dateTime={getISODato(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}>{getDato(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}</time>
      </li>
      <li>
        <label htmlFor="tidspunkt">Tidspunkt</label>
        <br />
        <time dateTime={getISOTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}>{getTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoFra)}</time>
        {" - "}
        <time dateTime={getISOTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoTil)}>{getTid(bildebevisPageIleggelseViewModel?.ileggelsesdatoTil)}</time>
      </li>
      <li>
        <label htmlFor="gatenavn">Gatenavn</label>
        <br />
        <span id="gatenavn">{bildebevisPageIleggelseViewModel?.gatenavn}</span>
      </li>
      <li>
        <label htmlFor="gatenummer">Gatenummer</label>
        <br />
        <span id="gatenummer">{bildebevisPageIleggelseViewModel?.gatenummer}</span>
      </li>
      <li>
        <label htmlFor="typegebyr">Type ileggelse</label>
        <br />
        <span id="typegebyr">{bildebevisPageIleggelseViewModel?.ileggelsetype}</span>
      </li>
      <li>
        <label htmlFor="forfallsdato">Forfallsdato</label>
        <br />
        <time dateTime={getISODato(bildebevisPageIleggelseViewModel?.forfallodato)}>{getDato(bildebevisPageIleggelseViewModel?.forfallodato)}</time>
      </li>
    </ul>
  );
};

const Gebyrinfo = ({ bildebevisPageIleggelseViewModel }) => {
  return (
    <>
      <h2>Om boten</h2>
      <TableBody
        bildebevisPageIleggelseViewModel={bildebevisPageIleggelseViewModel}
      />
    </>
  );
};

export default Gebyrinfo;
