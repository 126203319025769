import axios from "axios";
import url from "./url";
import { base64ToBlobUrl } from "../../utils/convert";

export const getBildebevisPageIleggelse = async (kode) => {
  let response = await axios.get(
    url.ileggelseService.getBildebevisPageIleggelse,
    { params: { kode: kode } }
  );
  if (response?.data && response.status) {
    return response.data;
  }
};

export const getBildebevisPageBildeBase64 = async (ileggelseId, bildeId) => {
  return await axios
    .get(
      url.ileggelseService.getBildebevisPageBildeBase64(ileggelseId, bildeId)
    )
    .then(async (response) => {
      const result = response?.data?.result;

      return Promise.resolve(base64ToBlobUrl(result?.base64));
    })
    .catch((error) => {
      console.log("error: ", error); // TODO redirect
    });
};
